html {
  font-size: 26px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 640px) {
  html {
    font-size: 16px;
  }
}
